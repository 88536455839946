import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map, Observable } from 'rxjs';
import { BonusService } from '@libs/app.services/wallet/bonus.service';

@Injectable({
  providedIn: 'root',
})
export class AccountResolver implements Resolve<boolean> {
  constructor(private bonusService: BonusService) {}

  resolve(): Observable<boolean> {
    return this.bonusService.basePromos().pipe(map(() => true));
  }
}
