<div class="wrapper">
  @if (config$ | async) {
    <router-outlet></router-outlet>
    @if ((selectAsideRight$ | async) || (selectAsideLeft$ | async)) {
    } @else {
      @if (tidioAvailable()) {
        <app-tidio-button />
      }
    }
  } @else {
    <div class="loading">
      <div class="spinner">
        <app-boxes-spinner />
      </div>
    </div>
  }
</div>
<div class="toast">
  <app-toast />
</div>
<div class="modal">
  <app-modal />
</div>
<app-router-loader />
